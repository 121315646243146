html {
  font-size: 15px !important;
}

body {
  background-color: #f5f6f8 !important;
  font-family: "Poppins", sans-serif !important;
  font-weight: 400 !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.react-datepicker__triangle {
  transform: translate(100px, 0px) !important;
}

/* .card-small {
  box-shadow: 0 2px 0 rgba(90, 97, 105, 0.11), 0 4px 8px rgba(90, 97, 105, 0.12),
    0 10px 10px rgba(90, 97, 105, 0.06), 0 7px 70px rgba(90, 97, 105, 0.1);
} */

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: initial;
  border: none !important;
  border-radius: 0.25rem;
  box-shadow: 0 0.125rem 0.625rem rgba(90, 97, 105, 0.12);
}

.card-header:first-child {
  border-radius: 0.625rem 0.625rem 0 0;
}

.card-small .card-body,
.card-small .card-footer,
.card-small .card-header {
  padding: 1rem;
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.border-bottom {
  border-bottom: 1px solid #e1e5eb !important;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: #ffffff !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-small .card-body,
.card-small .card-footer,
.card-small .card-header {
  padding: 1rem;
}
.card-body {
  padding: 1.875rem;
}
.card-body {
  flex: 1 1 auto;
  flex-direction: column;
  padding: 1.25rem;
}

.card-footer {
  padding: 1.09375rem 1.875rem;
  background-color: #fff;
  border-top: none;
}
.border-top {
  border-top: 1px solid #dee2e6 !important;
}
.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.navbar {
  box-shadow: 0 0.125rem 0.625rem rgba(90, 97, 105, 0.12);
}

.icon-primary {
  color: #01b4bc;
  transition: all 300ms;
}

.footer-overlay {
  z-index: 1049;
  position: fixed !important;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: right;
  box-shadow: 0 -0.125rem 0.625rem rgba(90, 97, 105, 0.12);
}

.standard-size {
  font-size: 1rem !important;
}

.small-size {
  font-size: 0.875rem !important;
  color: #918e8e !important;
}

.text-right {
  text-align: right;
}

.btn-icon {
  font-size: 24px !important;
  line-height: 24px !important;
  transition: all 300ms !important;
}

.icon-16 {
  border: none;
  background: transparent;
  font-size: 18px;
  padding: 0;
  transition: all 300ms;
}

.icon-24 {
  border: none;
  background: transparent;
  font-size: 24px;
  line-height: 36px;
  padding: 0;
  transition: all 300ms;
  color: #01b4bc;
}

.icon-24:hover {
  color: #01a3ac;
}

.grey-blue {
  color: #727272;
}

.grey-blue:hover {
  color: #01b4bc;
}

.green {
  color: #01b4bc;
}

.green:hover {
  color: #01a3ac;
}

.red {
  color: #dc3545;
}

.red:hover {
  color: #bb2d3b;
}

.grey-red {
  color: #727272;
}

.grey-red:hover {
  color: #dc3545;
}

.table-hover:hover {
  background-color: #f7f7f7;
}
.news-main-photo {
  position: relative;
  overflow: hidden;
  border-radius: 5px;
  transition: all 300ms;
  aspect-ratio: 3/2;
}

.news-main-photo img {
  transition: all 300ms;
  width: 100%;
}

.single-news-date {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 0.75rem;
  line-height: 0.75rem;
  color: #212529;
  font-weight: 400;
}

.single-news-title {
  color: #5f5f5f;
  font-size: 1.75rem;
  line-height: 1.875rem;
  font-weight: 700;
  font-family: "Roboto Condensed", sans-serif !important;
}

.single-news-text {
  font-family: "Montserrat", sans-serif !important;
  white-space: pre-wrap;
}

.single-news-intro a,
.single-news-intro a:hover,
.single-news-intro a:visited,
.single-news-text a,
.single-news-text a:hover,
.single-news-text a:visited {
  color: #01b4bc;
  text-decoration: none;
}

.single-events-date-time {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.single-events-date-icon {
  color: #01b4bc;
}

.single-events-date-value {
  margin-right: 0.5rem;
  color: #212529;
  font-weight: 300;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 0.75rem;
  line-height: 0.75rem;
}

.login-window {
  width: 80%;
  max-width: 600px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.list-container {
  display: flex;
  flex-direction: column;
  padding: 10px;
  background: #01b4bc;
}
.item-container {
  border-bottom: 1px solid rgb(222, 226, 230);
  padding: 8px;
  background: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.item-container:hover {
  background: #f7f7f7;
}

.scroll {
  height: 200px;
  overflow: auto;
}

.icon-button {
  border: none;
  background: transparent;
  transition: all 300ms;
  border-radius: 5%;
}

.icon-button:hover {
  background: #eeeeee;
}

#gridDemo {
  max-width: 100%;
  box-sizing: border-box;
  flex-basis: 0px;
  flex-grow: 1;
  overflow-y: auto;
  position: relative;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-row-gap: 20px;
  grid-column-gap: 20px;
  line-height: 24px;
}

.grid-square {
  border: 1px solid rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  justify-self: center;
  flex-direction: column;
  cursor: move;
}

.overlay-icons {
  padding: 5px;
}

.group-item {
  position: relative;
  white-space: normal;
  padding: 0.5rem;
  overflow: hidden;
  border-radius: 5px;
  background: rgb(1, 180, 188);
  background: linear-gradient(
    90deg,
    rgba(1, 180, 188, 1) 40%,
    rgba(1, 180, 188, 0.7) 100%
  );
  transition: all 300ms;
  min-height: 4.5625rem;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.group-item-inner {
  display: flex;
  justify-content: space-between;
  /* cursor: pointer; */
  gap: 0.5rem;
  align-self: center;
}

.group-item-inner-image {
  display: flex;
  max-width: 4.4375rem;
  width: -webkit-fill-available;
  min-height: 3.5rem;
}

.group-item-inner-image img {
  width: 80%;
  margin: 0 auto;
  filter: invert(1);
}

.group-item-inner-text {
  display: flex;
  flex-direction: column;
  align-self: center;
  overflow: hidden;
  width: -webkit-fill-available;
}

.group-item-inner-text-title {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1.25rem;
  line-height: 1.4375rem;
  font-weight: 700;
  color: #ffffff;
  text-transform: uppercase;
  padding-left: 1rem;
}

.group-item-inner-text-text {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1rem;
  color: #ffffff;
  white-space: nowrap;
  padding-left: 1rem;
}

.single-object-header {
  overflow: hidden;
  border-radius: 5px;
  aspect-ratio: 3/2;
}

.single-object-header img {
  width: 100%;
}

.scroll-snap-parent {
  scroll-snap-type: x mandatory;
}
.scroll-snap-child {
  scroll-snap-align: start;
}

.h-scroll {
  flex-wrap: nowrap !important;
  overflow: auto !important;
  padding-bottom: 0.7rem;
}

* {
  scrollbar-width: thin;
  scrollbar-color: rgb(1, 180, 188) rgba(255, 255, 255, 0);
}

*::-webkit-scrollbar {
  width: 16px;
}

*::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0);
}

*::-webkit-scrollbar-thumb {
  background-color: rgb(1, 180, 188);
  border-radius: 10px;
  border: 3px solid #ffffff;
}

.gallery-photo {
  border-radius: 5px;
  overflow: hidden;
  transition: all 300ms;
  cursor: pointer;
  /* box-shadow: 0px 0px 5px #00000038; */
  aspect-ratio: 3/2;
}

.gallery-photo:hover {
  opacity: 0.7;
}

.gallery-photo img {
  width: 100%;
}
.text {
  white-space: pre-wrap;
}

.subheader-wrap {
  border-bottom: 1px solid #01b4bc;
}

.header-inner {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  align-items: center;
}
.header-inner-left {
  width: 100%;
  overflow: hidden;
}
.subheader-title {
  color: #5f5f5f;
  font-size: 1.25rem;
  line-height: 1.25rem;
  font-weight: 700;
  font-family: "Roboto Condensed", sans-serif;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.user-menu-item a {
  color: rgb(33, 37, 41);
  text-decoration: none;
}
.user-menu-item a:hover {
  color: rgb(33, 37, 41);
  text-decoration: none;
}

.brand {
  color: #01b4bc;
}

.btn-primary {
  background: #01b4bc !important;
  border-color: #01b4bc !important;
}
.btn-primary:hover {
  background: #01a3ac !important;
  border-color: #01a3ac !important;
}

.btn-outline-primary {
  border-color: #01b4bc !important;
  color: #01b4bc !important;
}
.btn-outline-primary:hover {
  border-color: #01b4bc !important;
  background: #01b4bc !important;
  color: #ffffff !important;
}
.form-check-input:checked {
  background-color: #01b4bc !important;
  border-color: #01b4bc !important;
}
.nav-link {
  font-weight: 500 !important;
}

.cursor-arrow {
  cursor: default;
}

.item-container-ph {
  border-bottom: 1px solid rgb(222, 226, 230);
  padding: 8px;
  background: #ffffff;
}

.bigger {
  width: 85%;
  float: left;
}

.smaller {
  width: 15%;
  float: left;
  text-align: right;
}

.loader-wrap {
  position: absolute;
  display: flex;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.4);
  z-index: 10000;
}

.loader-inner {
  margin: auto;
}

.no-results {
  text-align: center;
  padding: 15px;
}

.comment-text {
  border-top: 1px solid rgba(222, 226, 230, 0.5);
  margin-top: 10px;
  padding: 10px 0;
}

.photo-frame {
  border: 1px solid rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  justify-self: center;
  flex-direction: column;
}

.date-holder {
  display: flex;
}

.date-label {
  display: flex;
  flex: 4;
  flex-direction: column;
  justify-content: center;
  text-align: end;
  margin-right: 8px;
}

.date-field {
  display: flex;
  flex: 8;
  flex-direction: column;
  justify-content: center;
}

.home-scroll {
  height: 295px;
  overflow: auto;
}

.notification-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.unseen {
  font-weight: 700;
}

.badge-fix {
  font-size: 0.75rem !important;
  vertical-align: middle !important;
  line-height: normal !important;
  padding: 3px 5px !important;
}

.grey {
  color: #999999;
}

.user {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgb(222, 226, 230);
  padding: 5px;
}

.user:hover {
  background: #f7f7f7;
}

.user-report {
  display: flex;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #e9ecef !important;
  color: #212529 !important;
  font-weight: 500;
}

.scroll-top-wrap {
  position: fixed;
  right: 5%;
  bottom: 10%;
  z-index: 1;
  cursor: pointer;
  color: #ffffff;
  background: #01b4bc;
  border-radius: 5px;
  width: 2.5rem;
  height: 2.5rem;
  align-items: center;
  justify-content: center;
  transition: all 300ms;
}
.scroll-top-wrap:hover {
  opacity: 0.8 !important;
}
.scroll-top-inner {
  display: flex;
}

.sticky-nav {
  position: sticky !important;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.act-as-link {
  color: #01a3ac;
  cursor: pointer;
}

.share-modal-body {
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
}

.share-link-wrap {
  display: flex;
  align-items: center;
  gap: 1em;
  justify-content: space-between;
}

.share-link-wrap a,
.share-link-wrap a:hover,
.share-link-wrap a:visited {
  text-decoration: none;
  color: #01a3ac;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.general-info-item {
  display: flex;
  align-items: center;
  gap: 1em;
  justify-content: space-between;
  padding: 5px;
}

.general-info-inner {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.general-info-item a,
.general-info-item a:hover,
.general-info-item a:visited {
  text-decoration: none;
  color: #01a3ac;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.flex {
  display: flex !important;
}

.margin-2 {
  margin-top: 2px;
}

#seo-tabs .nav-link {
  color: #000000;
}

#seo-tabs .nav-link.active {
  color: #ffffff;
  background-color: #01b4bc;
  border-color: #01b4bc;
}

.small-url {
  font-size: 0.875rem !important;
}

.small-url a,
.small-url a:hover,
.small-url a:visited {
  text-decoration: none;
  color: #01a3ac;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
